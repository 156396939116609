import { LegacyBankAccount } from './bank';
import { Currency, NumberBreakdown, Ownership, Total } from './finary';
import {
  AssetsDistributionResponse,
  HoldingsAccount,
  HoldingsAccountOwnership,
} from './holdings-account';
import { RealEstate, RealEstateBuildingType } from './real-estate';
import { SCPIAccount } from './scpi';

export type LoanCategory =
  | 'amortizing'
  | 'in_fine'
  | 'deferred_partial'
  | 'deferred_total'
  | 'step';

export enum FundableTargetType {
  UserScpi = 'UserScpi',
  RealEstate = 'RealEstate',
}

export type Fundable = {
  amount: number;
  target: SCPIAccount | RealEstate;
  target_id: number;
  target_type: FundableTargetType;
};

export type LoanAssetListItem = {
  building_type: RealEstateBuildingType | 'SCPI';
  id: number | string;
  name: string;
  price: number;
};

export type LoanStep = {
  current_month: {
    capital: number;
    insurance: number;
    interest: number;
    repayment: number;
  };
  display_monthly_repayment: number;
  elapsed_months: number;
  elapsed_months_percent: number;
  end_date: string;
  id: number;
  is_current_step: boolean;
  month_duration: number;
  monthly_repayment: number;
  name: string;
  remaining_months: number;
  start_date: string;
};

export type LoanPartialStep = Pick<
  LoanStep,
  'end_date' | 'id' | 'monthly_repayment' | 'start_date' | 'name'
>;

export type Loan = {
  contribution: number | null;
  currency: Currency;
  deferred_start_date: string | null;
  display_currency: Currency;
  display_current_month: {
    capital: number | null;
    insurance: number | null;
    interests: number | null;
    repayment: number | null;
  } | null;
  display_monthly_repayment: string;
  display_outstanding_amount: number;
  display_outstanding_capital: number;
  display_paid: {
    capital: number;
    insurance: number;
    interest: number;
    percent: number;
    total: number;
  } | null;
  display_repartition: {
    capital: number;
    fixed_costs: number;
    interest_insurance: number;
    total_cost: number;
  } | null;
  elapsed_months: number;
  end_date: string;
  fixed_costs: number | null;
  id: number;
  insurance_rate: number | null;
  interest_rate: number | null;
  loan_category: LoanCategory;
  month_duration: number;
  monthly_repayment: number;
  name: string;
  organization_contribution: number | null;
  organization_monthly_repayment: number;
  organization_total_amount: number;
  outstanding_amount: number;
  outstanding_capital: number;
  // @deprecated - ownership_percentage should not be used anymore, it will be integrated in `ownership_repartition`
  ownership_percentage: number;
  paid: {
    capital: number;
    insurance: number;
    interest: number;
    percent: number;
    total: number;
  } | null;
  qualified: boolean;
  remaining_months: number;
  repartition: {
    capital: number;
    fixed_costs: number;
    interest_insurance: number;
    total_cost: number;
  } | null;
  start_date: string;
  total_amount: number;
  account?: HoldingsAccount;
  bank_account?: LegacyBankAccount;
  fundables?: Fundable[];
  steps?: LoanPartialStep[];
};

export type LoansSummaryResponse = {
  accounts: HoldingsAccount[];
  total: Total;
};

export type LoansDistributionResponse = AssetsDistributionResponse;

export type LoansAmortizationResponse = {
  timeseries: [string, number][];
  total: number;
};

export type LoansInsights = {
  average_loan_duration: number | null;
  data: Loan[];
  distribution: Record<string, Pick<NumberBreakdown, 'amount' | 'share'>>;
  leverage: number;
  loan_capacity: number | null;
  loan_to_value: number | null;
  monthly: {
    capital: number | null;
    insurance: number | null;
    interests: number | null;
    repayment: number | null;
  };
  ownership_repartition: Ownership[];
  reimbursed_percentage: number;
  taeg: number | null;
  total: Total;
};

export type LoanStepForm = {
  end_date: string;
  monthly_repayment: number;
  start_date: string;
  name?: string | null;
};

export type LoanFormData = {
  contribution: number | null;
  deferred_start_date: string | null;
  fixed_costs: number | null;
  fundables: Omit<Fundable, 'target'>[] | null;
  insurance_rate: number | null;
  interest_rate: number | null;
  loan_category: LoanCategory;
  month_duration: number | null;
  monthly_repayment: number | null;
  name: string;
  start_date: string | null;
  steps: LoanStepForm[] | null;
  total_amount: number;
  ownership_repartition?: HoldingsAccountOwnership[];
};

export type LoanInvalidParameter =
  | 'monthly_repayment'
  | 'start_date'
  | 'total_repayment'
  | 'fundables'
  | 'loan_category'
  | 'name'
  | 'total_amount'
  | 'interest_rate'
  | 'month_duration'
  | 'insurance_rate'
  | 'contribution'
  | 'fixed_costs'
  | 'deferred_start_date';
