import classNames from 'classnames';
import * as React from 'react';

import { useCombinedRefs } from '../../hooks/useCombinedRefs';
import { useOverflow } from '../../hooks/useOverflow';
import { sprinkles, Sprinkles } from '../../styles/sprinkles.css';
import { formatHiddenContent } from '../../utils/formatter';
import { getSprinklesProps } from '../../utils/sprinkles';
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '../Polymorphic';
import { Tooltip } from '../Tooltip';

import {
  TypographyRecipeVariants,
  typographyStyles,
  TypographyVariants,
} from './Typography.css';

export const FREE_USER_DISPLAY_VALUE = '⦿⦿⦿⦿⦿⦿';

export type { TypographyVariants };

export type TypographyColors = Sprinkles['color'];

export type TypographyProps<C extends React.ElementType> =
  PolymorphicComponentPropWithRef<
    C,
    Sprinkles &
      TypographyRecipeVariants & {
        /**
         *  Hide value by displaying a fake and blured one
         */
        hideValue?: boolean;
        lineClamp?: React.CSSProperties['lineClamp'];
        showTooltipWhenOverflowing?: boolean;
        useTooltipPortal?: boolean;
        whiteSpace?: Sprinkles['whiteSpace'];
        wordBreak?: Sprinkles['wordBreak'];
      }
  >;

export type TypographyComponent = <C extends React.ElementType = 'div'>(
  props: TypographyProps<C>
) => React.ReactNode | null;

export const Typography: TypographyComponent & { displayName?: string } =
  React.forwardRef(
    <C extends React.ElementType = 'div'>(
      {
        as,
        variant,
        lineClamp,
        className,
        showTooltipWhenOverflowing,
        useTooltipPortal,
        hideValue,
        ...componentProps
      }: TypographyProps<C>,
      ref?: PolymorphicRef<C>
    ) => {
      const innerRef = React.useRef<HTMLElement>();
      const combinedRef = useCombinedRefs(ref, innerRef);
      const { overflows } = useOverflow(
        {
          ref: innerRef,
          disabled: !showTooltipWhenOverflowing,
        },
        [combinedRef]
      );

      // Main component props
      const Component = as || 'span';
      const { sprinklesProps, otherProps } = getSprinklesProps(componentProps);
      const withLineClamp: React.CSSProperties | undefined = lineClamp
        ? {
            display: '-webkit-box',
            WebkitLineClamp: lineClamp,
            WebkitBoxOrient: 'vertical' as const,
            overflow: 'hidden',
          }
        : undefined;
      const { children, ...restProps } = otherProps;

      // Main component
      const typography = (
        <Component
          ref={combinedRef}
          style={withLineClamp}
          className={classNames(
            typographyStyles({ variant }),
            sprinkles({
              ...sprinklesProps,
              filter: hideValue
                ? 'blurSmall'
                : (sprinklesProps.filter as Sprinkles['filter']),
            }),
            className
          )}
          {...restProps}
        >
          {hideValue
            ? ['string', 'number'].includes(typeof children)
              ? formatHiddenContent(children as string | number)
              : FREE_USER_DISPLAY_VALUE
            : (children as React.ReactNode)}
        </Component>
      );

      // Wrap in tooltip when required
      const innerText = innerRef.current?.innerText;
      return showTooltipWhenOverflowing &&
        overflows &&
        innerText &&
        !hideValue ? (
        <Tooltip
          size="small"
          title={innerText}
          usePortal={useTooltipPortal}
          contentProps={{
            style: { maxWidth: 500 },
          }}
        >
          {typography}
        </Tooltip>
      ) : (
        typography
      );
    }
  );

Typography.displayName = 'Typography';
