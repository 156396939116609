import { OAuthStrategy } from '@clerk/types';
import type {
  AssetCategory,
  AssetCategoryNewFormat,
  HoldingsAccount,
  Loan,
  RealEstate,
  SCPIAccount,
  TransactionType,
} from 'core-api';
import { isHoldingAccountManual } from 'core-api/utils/holdings';
import { isSCPI } from 'core-api/utils/real-estates';
import { MAP_INVEST_ASSETS_TO_SCREENER_SLUG } from 'core-api/constants/invest';
import { newAssetCategoryToOldFormat } from 'core-api/utils/assetCategory';

import { AddManualSecurity } from '/components/add-assets/investment-accounts/AddManualSecurityForm';
import type { AddManualStartup } from '/pages/add-assets/startups/manually';
import type { TrialClickOrigin } from '/services/analytics';
import { AddManualPreciousMetalsParams } from '/pages/add-assets/precious-metals';

// TODO - we should probably refactor this into an exported object
export const getCategoryUrl = (
  category: AssetCategoryNewFormat | AssetCategory
) =>
  `/portfolio/${newAssetCategoryToOldFormat(category as AssetCategoryNewFormat)
    .replace('commodities', 'precious_metals')
    .replaceAll('_', '-')}`;

export const getInvestmentAccountWrapperUrl = (
  wrapper: HoldingsAccount | { id: string }
) => {
  return `${getCategoryUrl('investment_accounts')}/${wrapper.id}`;
};

export const getRealEstateAssetUrl = (
  asset: SCPIAccount | RealEstate | undefined
) => (asset ? getRealEstateOrScpiAssetUrl(asset, isSCPI(asset)) : '');

export const getRealEstateOrScpiAssetUrl = (
  asset: { id: string | number },
  isSCPI?: boolean
) => {
  return `${getCategoryUrl('real_estates')}${isSCPI ? '/scpis' : ''}/${
    asset.id
  }`;
};

export const getLoanAssetUrl = (loan: Loan | undefined) =>
  loan ? `${getCategoryUrl('loans')}/${loan.id}` : '';

export const getCryptoWrapperUrl = (
  wrapper: HoldingsAccount | { id: string }
) => {
  return `${getCategoryUrl('cryptos')}/${wrapper.id}`;
};

export const getHoldingAccountAssetUrl = (
  account: Pick<
    Partial<HoldingsAccount>,
    'id' | 'loans' | 'real_estates' | 'scpis' | 'institution_connection'
  >,
  category: AssetCategory
) => {
  switch (category) {
    case 'cryptos':
    case 'investment_accounts':
      return `${getCategoryUrl(category)}/${account.id}`;
    case 'loans':
      return getLoanAssetUrl(account.loans?.[0]);
    case 'real_estates':
      // if account is a scpi wrapper, we redirect to real estates page
      if (account.scpis?.[0] && !isHoldingAccountManual(account)) {
        return getCategoryUrl('real_estates');
      }
      return getRealEstateAssetUrl(
        account.real_estates?.[0] ?? account.scpis?.[0]
      );
    default:
      return undefined;
  }
};

/**
 * Returns a sharing route path if a sharingLinkId is provided
 */
export const sharingRoute = (route: string, sharingLinkId?: string) => {
  if (sharingLinkId) {
    return `/share/${sharingLinkId}${route}`;
  }

  return route;
};

const urlWithParams =
  <
    T extends Record<string, string> | undefined = undefined,
    R extends string | undefined = undefined,
  >(
    url: string
  ) =>
  (query?: T, anchor?: R) =>
    `${url}${query ? `?${new URLSearchParams(query).toString()}` : ''}${
      anchor ? `#${anchor}` : ''
    }`;

export type PaywallQueryParams = {
  manualLimitation?: 'true';
  next?: string;
  onlyPro?: 'true';
  origin?: TrialClickOrigin;
};

export type SsoQueryParams = { sig?: string; sso?: string };

export type AddAssetsConnectIndexQueryParams = {
  bankSlug: string;
  providerId: string;
  backHref?: string;
  connectionId?: string;
  logo_url?: string;
};

export const APP_ROUTE = {
  add_assets: {
    index: '/add-assets',
    bank: {
      index: '/add-assets/bank',
      manually: '/add-assets/bank/manually',
    },
    institution: {
      index: urlWithParams<{
        backHref?: string;
        type?: 'investment_accounts' | 'bank' | 'loans';
      }>('/add-assets/institution'),
      provider: {
        byId: (id: string) =>
          urlWithParams<{ backHref?: string }>(
            `/add-assets/institution/provider/${id}`
          ),
      },
    },
    investment_accounts: {
      index: '/add-assets/investment-accounts',
      manually: urlWithParams<
        Partial<AddManualSecurity> & {
          backHref?: string;
          holdingAccountId?: string;
        }
      >('/add-assets/investment-accounts/manually'),
    },
    connect: {
      index: urlWithParams<AddAssetsConnectIndexQueryParams>(
        '/add-assets/connect'
      ),
      // connection_id is also expected on mobile and set by the backend so this should not change
      connection: urlWithParams<{ connection_id: string }>(
        '/add-assets/connect/connection'
      ),
    },
    crowdlendings: urlWithParams<{ holdingAccountId?: string }>(
      `/add-assets/crowdlendings`
    ),
    cryptos: {
      index: '/add-assets/cryptos',
      wallet: '/add-assets/cryptos/wallet',
      manually: urlWithParams<{
        correlation_id?: string;
        holdingAccountId?: string;
        logo_url?: string;
        name?: string;
      }>('/add-assets/cryptos/manually'),
      exchange: urlWithParams<{
        backHref?: string;
        logo_url?: string;
        name?: string;
        slug?: string;
      }>('/add-assets/cryptos/exchange'),
      import: urlWithParams<{
        institution_provider_id: string;
        logo_url: string;
        name: string;
        slug: string;
        backHref?: string;
        holdingAccountId?: string;
      }>('/add-assets/cryptos/import'),
    },
    loans: {
      index: '/add-assets/loans',
      manually: '/add-assets/loans/manually',
    },
    real_estates: {
      index: '/add-assets/real-estates',
      physical: '/add-assets/real-estates/physical',
      scpi: (query?: {
        correlation_id?: string;
        logo_url?: string;
        name?: string;
      }) =>
        `/add-assets/real-estates/scpi${
          query ? `?${new URLSearchParams(query).toString()}` : ''
        }`,
    },
    startups: {
      index: '/add-assets/startups',
      manually: urlWithParams<AddManualStartup>(
        '/add-assets/startups/manually'
      ),
    },
    precious_metals: urlWithParams<AddManualPreciousMetalsParams>(
      '/add-assets/precious-metals'
    ),
    life_insurance: {
      index: '/add-assets/life-insurance',
      fonds_euro: '/add-assets/life-insurance/fonds-euro',
    },
    other_assets: '/add-assets/other-assets',
    watches: urlWithParams<{
      correlation_id?: string;
      logo_url?: string;
      name?: string;
    }>('/add-assets/watches'),
  },
  advisor: '/advisor',
  add_assets_syncing: '/add-assets/syncing',
  dashboard: urlWithParams<undefined>('/'),
  cashflow: {
    index: '/cashflow',
    category: {
      subcategory: {
        byId: (id: string | number) =>
          urlWithParams<{ type?: TransactionType }>(
            `/cashflow/category/subcategory/${id}`
          ),
      },
      byId: (id: string | number) =>
        urlWithParams<{ type?: TransactionType }>(`/cashflow/category/${id}`),
    },
  },
  forgot_password: '/login/forgot',
  invest: {
    index: '/invest',
    kyc: {
      index: '/invest/kyc',
      verify_email: '/invest/kyc/verify-email',
      verification: {
        index: '/invest/kyc/verification',
        status: '/invest/kyc/verification/status',
      },
      error: '/invest/kyc/error',
      business_creation: '/invest/kyc/business-creation',
    },
    savings_plans: {
      create: '/invest/savings-plans/create',
      byId: (id: string) => ({
        edit: `/invest/savings-plans/${id}/edit`,
      }),
    },
  },
  insights: '/insights',
  login_otp: urlWithParams<SsoQueryParams>('/login/otp'),
  login: urlWithParams<SsoQueryParams>('/login'),
  oauth_callback: urlWithParams<SsoQueryParams>('/oauth-callback'),
  oauth_success: urlWithParams<SsoQueryParams & { from?: string }>(
    '/oauth-success'
  ),
  paywall: urlWithParams<PaywallQueryParams>('/premium'),
  portfolio: {
    index: '/portfolio',
    loans: '/portfolio/loans',
    real_estates: '/portfolio/real-estates',
    checking_accounts: '/portfolio/checking-accounts',
    other_assets: '/portfolio/other-assets',
    credit_accounts: '/portfolio/credit-accounts',
    savings_accounts: '/portfolio/savings-accounts',
    startups: '/portfolio/startups',
    investment_accounts: '/portfolio/investment-accounts',
    crowdlendings: '/portfolio/crowdlendings',
    commodities: '/portfolio/precious-metals',
    fonds_euro: '/portfolio/fonds-euro',
    cryptos: {
      index: '/portfolio/cryptos',
      byId: (id: string) => `/portfolio/cryptos/${id}`,
    },
  },
  signup: {
    index: urlWithParams<SsoQueryParams>('/signup'),
    referral: '/signup/referral',
    verify_email: '/signup/verify-email',
    add_assets: '/signup/add-assets',
    investor_goal: '/signup/investor-goal',
  },
  settings: {
    index: urlWithParams<{ fromSocialProvider?: OAuthStrategy }>('/settings'),
    security: '/settings/security',
    connections: '/settings/connections',
    profiles: {
      index: urlWithParams<{ tab?: 'people' | 'companies' }>(
        '/settings/profiles'
      ),
      byMembershipId: (membershipId: string) => ({
        allocation: `/settings/profiles/${membershipId}/allocation`,
      }),
    },
    investor_profile: '/settings/investor',
    clean_my_graph: '/settings/clean-my-graph',
    subscription: '/settings/subscription',
    payment_methods: '/settings/payment-methods',
  },
  referral: {
    index: '/referral',
  },
};

export const coinbaseAuthUrl = () => {
  const params = new URLSearchParams();

  params.append('response_type', 'code');
  params.append('client_id', process.env.NEXT_PUBLIC_COINBASE_CLIENT_ID ?? '');
  params.append(
    'redirect_uri',
    process.env.NEXT_PUBLIC_COINBASE_REDIRECT_URI_DASHBOARD ?? ''
  );
  params.append('state', 'coinbase');
  params.append('scope', process.env.NEXT_PUBLIC_COINBASE_SCOPE ?? '');
  params.append('account', 'all');

  return `https://www.coinbase.com/oauth/authorize?${params.toString()}`;
};

export const getUserReferralUrl = (referral_id: string) =>
  `${process.env.NEXT_PUBLIC_WEBSITE_URL}/referral/${referral_id}`;

export const getInvestAssetScreenerUrl = (assetId: string) =>
  MAP_INVEST_ASSETS_TO_SCREENER_SLUG[assetId]
    ? `${process.env.NEXT_PUBLIC_WEBSITE_URL}/fr/crypto/coins/${MAP_INVEST_ASSETS_TO_SCREENER_SLUG[assetId]}`
    : undefined;

/**
 * Public paths that don't require authentication
 */
export const PUBLIC_PATHS = [
  APP_ROUTE.login(),
  APP_ROUTE.login_otp(),
  APP_ROUTE.signup.index(),
  APP_ROUTE.forgot_password,
  APP_ROUTE.oauth_callback(),
  APP_ROUTE.oauth_success(),
  '/404',
  /^\/share\/?.*$/,
];

export const PUBLIC_ONLY_PATHS = [
  APP_ROUTE.login(),
  APP_ROUTE.login_otp(),
  APP_ROUTE.signup.index(),
];

export const EXTERNAL_URL = {
  finary: {
    terms: 'https://finary.com/terms',
  },
};
