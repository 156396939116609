import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  ApiGetProps,
  DividendSubtype,
  DividendsResponse,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type UsePortfolioRealEstatesDividendsProps = {
  assetTypes?: DividendSubtype[];
} & ApiGetProps;

export const usePortfolioRealEstatesDividends = ({
  sharingLinkId,
  accessCode,
  skip,
  assetTypes,
}: UsePortfolioRealEstatesDividendsProps) => {
  const { membershipId, organizationId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('with_real_estate', 'true');

  if (assetTypes?.length) {
    assetTypes.forEach((type) => {
      params.append('asset_types[]', type);
    });
  }

  return useApi<DividendsResponse>(
    API_ROUTES.portfolio.real_estates.dividends.get({
      membershipId,
      organizationId,
      params,
    }),
    undefined,
    !!sharingLinkId,
    skip
  );
};
