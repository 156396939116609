import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  ApiGetProps,
  DividendSubtype,
  DividendsResponse,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type UsePortfolioRealEstatesByAccountDividendsProps = {
  accountId: string | undefined;
  assetTypes?: DividendSubtype[];
} & ApiGetProps;

export const usePortfolioRealEstatesByAccountDividends = ({
  accountId,
  sharingLinkId,
  accessCode,
  skip,
  assetTypes,
}: UsePortfolioRealEstatesByAccountDividendsProps) => {
  const { membershipId, organizationId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  if (assetTypes?.length) {
    assetTypes.forEach((type) => {
      params.append('asset_types[]', type);
    });
  }

  return useApi<DividendsResponse>(
    accountId
      ? API_ROUTES.portfolio.real_estates.accountById(accountId).dividends.get({
          membershipId,
          organizationId,
          params,
        })
      : null,
    undefined,
    !!sharingLinkId,
    skip
  );
};
