import {
  INVEST_DEFAULT_CRYPTO,
  INVEST_DEFAULT_FIAT_CURRENCY,
} from 'core-api/constants/invest';
import { InvestAsset } from 'core-api/types/invest';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

/**
 * This atom is used to store the currently selected ledger id
 */
export const investLedgerIdAtom = atomWithStorage<string | null>(
  'investLedgerId',
  null
);

export type InvestTradingAction = 'buy' | 'sell' | 'swap';

export type InvestTradingAtom = {
  action: InvestTradingAction;
  directTransactionId: string | undefined;
  sourceAssetId: string | undefined;
  sourceQuantity: string;
  step: 'preview' | 'summary' | 'processing';
  targetAssetId: string | undefined;
};

/**
 * This atom is used to store the current trading action and assets
 */
export const investTradingAtom = atom<InvestTradingAtom>({
  action: 'buy',
  step: 'preview',
  sourceAssetId: INVEST_DEFAULT_FIAT_CURRENCY,
  targetAssetId: INVEST_DEFAULT_CRYPTO,
  sourceQuantity: '',
  directTransactionId: undefined,
});

export type InvestKycGlobalStep = 'verify-your-identity' | 'stepper';

export const investKycGlobalStepAtom = atom<InvestKycGlobalStep>(
  'verify-your-identity'
);

export const investSavingPlanFormAllocationsAtom = atom<
  {
    asset: InvestAsset;
    quantity: number | undefined;
  }[]
>([]);

export type InvestDepositPaymentMode = 'credit-card' | 'sepa';

export const investDepositPaymentModeAtom =
  atomWithStorage<InvestDepositPaymentMode>('investDepositPaymentMode', 'sepa');

export type InvestHomeTabOption =
  | 'positions'
  | 'saving_plans'
  | 'trading'
  | 'transactions';

export const investHomeTabAtom = atom<InvestHomeTabOption>('positions');
