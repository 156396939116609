import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form';

import { Box, BoxProps } from '../Box';

export type FormProps<T extends FieldValues> = {
  form: UseFormReturn<T>;
  onSubmit?: SubmitHandler<T>;
} & Omit<BoxProps<'form'>, 'onSubmit'>;

export const Form = <T extends FieldValues>({
  form,
  onSubmit,
  children,
  ...props
}: FormProps<T>) => (
  <FormProvider {...form}>
    <Box
      display="block"
      as="form"
      {...props}
      onSubmit={onSubmit ? form.handleSubmit(onSubmit) : undefined}
    >
      {children}
    </Box>
  </FormProvider>
);
