import { atomWithStorage } from 'jotai/utils';

/**
 * This atom is used to determine if the user is onboarding or not
 */
export const isOnboardingAtom = atomWithStorage('isOnboarding', false);

export const isOnboardingTasksDismissedAtom = atomWithStorage(
  'isOnboardingTasksDismissed',
  false
);
