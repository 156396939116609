import { useTranslation } from 'next-i18next';

import { AvailabilityIcon } from '/components/questionnaire/AvailabilityIcon';
import { IntegrationsIcon } from '/components/questionnaire/IntegrationsIcon';
import { ReviewsIcon } from '/components/questionnaire/ReviewsIcon';
import { SecurityIcon } from '/components/questionnaire/SecurityIcon';

import { Box } from 'ui/components/Box';
import { Typography } from 'ui/components/Typography';
import { useUIState } from 'ui/providers/UIProvider';
import { MEDIUM_CONTENT_SIZE } from 'ui/styles/contentSize';
import { theme } from 'ui/styles/theme.css';

import { Interpolate } from '/components/common/Interpolate';
import { useNextTheme } from '/hooks/useNextTheme';

export const PreboardingSidePanel = () => {
  const { t } = useTranslation(['pages']);
  const { locale } = useUIState();
  const { getThemeAsset } = useNextTheme();
  const { isDark } = useNextTheme();

  const linearGradient = isDark
    ? `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 1)`
    : `linear-gradient(to bottom, rgba(245, 246, 247, 0), rgba(245, 246, 247, 0) 40%, rgba(245, 246, 247, 1)`;

  const radialGradient = isDark
    ? `radial-gradient(90.90% 85.10% at 110.41% 54.61%, rgba(30, 30, 89, 0.75) 0%, rgba(0, 0, 0, 0.00) 90%), radial-gradient(103.05% 104.44% at -16.76% 71.20%, rgba(30, 30, 89, 0.35) 0%, rgba(0, 0, 0, 0.00) 100%), radial-gradient(174.89% 134.50% at 64.66% -19.15%, rgba(241, 192, 134, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%)`
    : `radial-gradient(428.04% 92.48% at 29.64% 53.05%, rgba(214, 209, 255, 0.25) 0%, rgba(237, 222, 255, 0.25) 21.7%, rgba(255, 222, 242, 0.25) 42.53%, rgba(255, 234, 229, 0.25) 63.89%, rgba(255, 231, 214, 0.25) 83.16%, rgba(255, 226, 207, 0.25) 100%)`;

  return (
    <Box
      display={['none', 'none', 'none', 'flex']}
      justifyContent="flex-end"
      borderRadius="medium"
      flex={1}
      margin="s4"
      style={{
        height: `calc(100% - ${theme.spacing.s4} * 2)`,
        backgroundImage: radialGradient,
        marginLeft: 0,
      }}
    >
      <Box
        borderRadius="medium"
        style={{
          height: '100%',
          width: '100%',
          backgroundImage: `url(${getThemeAsset(
            `/v2/images/signup-background-${locale}.png`
          )})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right 20%',
        }}
      >
        <Box
          style={{
            width: '100%',
            backgroundImage: linearGradient,
          }}
        >
          <Box
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="center"
            paddingBottom="s10"
            marginBottom="s10"
            textAlign="center"
            paddingHorizontal="s6"
            gap="s5"
            style={{
              maxWidth: MEDIUM_CONTENT_SIZE,
              margin: '0 auto',
            }}
          >
            <Typography variant="displayMedium">
              <Interpolate ns="pages" i18nKey="preboarding_side_panel.title" />
            </Typography>
            <Typography variant="bodyMedium" color="tertiary">
              {t('pages:preboarding_side_panel.description')}
            </Typography>
            <Box style={{ marginTop: 40 }} gap="s6">
              <Box
                flexDirection="column"
                alignItems="center"
                gap="s3"
                style={{ maxWidth: '5em' }}
              >
                <SecurityIcon />
                <Typography variant="bodyXSmall" color="tertiary">
                  {t('pages:preboarding_side_panel.security')}
                </Typography>
              </Box>
              <Box
                flexDirection="column"
                alignItems="center"
                gap="s3"
                style={{ maxWidth: '5em' }}
              >
                <ReviewsIcon />
                <Typography variant="bodyXSmall" color="tertiary">
                  {t('pages:preboarding_side_panel.reviews')}
                </Typography>
              </Box>
              <Box
                flexDirection="column"
                alignItems="center"
                gap="s3"
                style={{ maxWidth: '5em' }}
              >
                <IntegrationsIcon />
                <Typography variant="bodyXSmall" color="tertiary">
                  {t('pages:preboarding_side_panel.integrations')}
                </Typography>
              </Box>
              <Box
                flexDirection="column"
                alignItems="center"
                gap="s3"
                style={{ maxWidth: '5em' }}
              >
                <AvailabilityIcon />
                <Typography variant="bodyXSmall" color="tertiary">
                  {t('pages:preboarding_side_panel.availability')}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
