import { daysDiff } from 'utils';
import { capitalize } from 'utils/string';

import { User, UserPlanLevel } from '../types';

/**
 * Returns the age of a given user in days, with the age being the time since
 * the user was created
 */
export const getUserAgeInDays = (user: User | undefined): number => {
  if (!user) {
    return 0;
  }
  return daysDiff(new Date(user.created_at), new Date());
};

/**
 * Returns the email username (capitalized)
 */
export const getUserCapitalizedEmailUsername = (user?: User | null) => {
  const email = user?.email;
  const emailUsername = email?.split('@')[0];
  return emailUsername ? capitalize(emailUsername) : undefined;
};

/**
 * Returns the display name for a given user (either the first name or
 * the email username)
 * @param user
 */
export const getUserDisplayName = (user?: User | null) => {
  return user?.firstname || getUserCapitalizedEmailUsername(user);
};

/**
 * Returns the full name, or the email username if the full name is not defined
 */
export const getUserDisplayFullName = (user?: User | null) => {
  return user?.firstname
    ? `${user.firstname} ${user?.lastname ?? ''}`.trim()
    : getUserCapitalizedEmailUsername(user);
};

/**
 * Returns the initials for a given user
 * @param user
 */
export const getUserInitials = (user?: User | null) => {
  const displayName = getUserDisplayFullName(user);
  return user?.firstname
    ? `${user.firstname[0]}${user?.lastname?.[0] ?? ''}`
    : displayName?.[0];
};

/**
 * Check if the user is in trial period
 */
export const isSubscriptionTrialUser = (user?: User | null) =>
  !!user?.subscription_status &&
  ['trial', 'trial_lite', 'trial_pro'].includes(user.subscription_status);

/**
 * Check if user has access to Finary Lite
 * Includes Pro, Plus and Lite (trial and non trial)
 */
export const isLiteAccessUser = (user?: User | null) =>
  !!user?.subscription_status &&
  (['lite', 'trial_lite'].includes(user.subscription_status) ||
    isPlusAccessUser(user) ||
    isProAccessUser(user));

/**
 * Check if user has access to Finary Plus
 * Include Plus and Pro (trial and non trial)
 */
export const isPlusAccessUser = (user?: User | null) =>
  !!user?.subscription_status &&
  (['premium', 'trial'].includes(user.subscription_status) ||
    isProAccessUser(user));

/**
 * Check if user has access to Finary Pro
 * Include Pro (trial and non trial)
 */
export const isProAccessUser = (user?: User | null) =>
  !!user?.subscription_status &&
  ['trial_pro', 'pro'].includes(user.subscription_status);

/**
 * Check if user has no subscription
 */
export const isOnlyFreeAccessUser = (user?: User | null) =>
  !isLiteAccessUser(user) && !isPlusAccessUser(user) && !isProAccessUser(user);

/**
 * Check if user hasn't finish the onboarding or has no assets
 */
export const isNewUser = (user?: User | null): boolean => {
  return (
    !!user && !user.has_assets_ownership && !hasUserFinishedOnboarding(user)
  );
};

export const hasUserFinishedOnboarding = (user?: User | null): boolean => {
  return (
    !!user &&
    !!user.onboarding_steps.find(
      ({ step, state }) => step === 'add_first_asset' && state === 'done'
    )
  );
};

export const getUserPlanLevel = (user?: User | null): UserPlanLevel => {
  if (isProAccessUser(user)) {
    return 'pro';
  }
  if (isPlusAccessUser(user)) {
    return 'plus';
  }
  if (isLiteAccessUser(user)) {
    return 'lite';
  }
  return 'free';
};
