import { ApiGetProps } from '../../types';
import { useSharingLinkPublicInfoById } from '../sharing';

import { useUIConfiguration } from './useUIConfiguration';

export const useSecretMode = ({ sharingLinkId, accessCode }: ApiGetProps) => {
  const { data: sharingLinkPublicInfo } =
    useSharingLinkPublicInfoById(sharingLinkId);
  const { data: configuration, update: updateUiConfiguration } =
    useUIConfiguration({ sharingLinkId, accessCode });

  const currentSecretMode = sharingLinkId
    ? !sharingLinkPublicInfo?.is_amount_display_enabled ||
      !sharingLinkPublicInfo?.is_quantity_display_enabled
    : configuration.secret_mode;

  const update = (secretMode: boolean) =>
    updateUiConfiguration({ secret_mode: secretMode });

  const toggle = () => {
    update(!currentSecretMode);
  };

  return {
    update,
    toggle,
    isSecretMode: currentSecretMode,
  };
};
