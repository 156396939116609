import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  ApiGetProps,
  DividendSubtype,
  DividendsResponse,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type UsePortfolioInvestmentsByAccountDividendsProps = {
  accountId?: string;
  assetTypes?: DividendSubtype[];
} & ApiGetProps;

export const usePortfolioInvestmentsByAccountDividends = ({
  accountId,
  sharingLinkId,
  accessCode,
  skip,
  assetTypes,
}: UsePortfolioInvestmentsByAccountDividendsProps) => {
  const { membershipId, organizationId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('with_real_estate', 'true');

  if (assetTypes?.length) {
    assetTypes.forEach((type) => {
      params.append('asset_types[]', type);
    });
  }

  return useApi<DividendsResponse>(
    accountId
      ? API_ROUTES.portfolio.investments.accountById(accountId).dividends.get({
          membershipId,
          organizationId,
          params,
        })
      : null,
    {
      keepPreviousData: true,
    },
    !!sharingLinkId,
    skip
  );
};
