import { useFinaryApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import {
  CashflowDistributionParams,
  CashflowDistribution,
  TransactionParentCategory,
  ApiGetProps,
} from '../../types';
import { createSharingLinkParams } from '../../utils';
import { useApi } from '../useApi';

export type UseCashflowCategoryDistributionProps = ApiGetProps &
  CashflowDistributionParams & {
    categoryId?: number;
  };

export const useCashflowCategoryDistribution = ({
  sharingLinkId,
  accessCode,
  type,
  dateRange,
  categoryId,
  skip,
}: UseCashflowCategoryDistributionProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  params.set('type', type);

  if (dateRange.start_date && dateRange.end_date) {
    params.set('period', dateRange.period);
    params.set('start_date', dateRange.start_date);
    params.set('end_date', dateRange.end_date);
  }

  const result = useApi<CashflowDistribution>(
    dateRange.start_date && dateRange.end_date && categoryId
      ? API_ROUTES.cashflow.distribution.byId(categoryId).get({
          organizationId,
          membershipId,
          params,
        })
      : null,
    { keepPreviousData: true },
    !!sharingLinkId,
    skip
  );

  return {
    ...result,
    mutateDistributionCategory: (category: TransactionParentCategory) =>
      result.mutate((distribution) =>
        distribution
          ? {
              ...distribution,
              category,
            }
          : undefined
      ),
  };
};
