import { Ownership, HoldingsAccountOwnership } from '../types';

export const hasMultipleOwners = (account: {
  ownership_repartition?: Ownership[] | HoldingsAccountOwnership[];
}) => {
  const ownership = account.ownership_repartition ?? [];
  return ownership.length > 1;
};

export const hasNotCompleteOwnership = (account: {
  ownership_repartition?: Ownership[] | HoldingsAccountOwnership[];
}) => {
  const totalOwnershipShare = account.ownership_repartition?.reduce(
    (acc, ownership) => acc + ownership.share,
    0
  );

  return !!totalOwnershipShare && totalOwnershipShare < 1;
};
