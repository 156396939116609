import { Transaction } from 'core-api';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export type SelectedTransactionsAtom = {
  transactions: Transaction[];
  type: 'single' | 'multiple';
};

export const selectedTransactionsAtom = atom<SelectedTransactionsAtom>({
  transactions: [],
  type: 'single',
});

export type TransactionSmartRuleSortType = 'date' | 'transactionCount';

export const transactionSmartRuleSortTypeAtom =
  atomWithStorage<TransactionSmartRuleSortType>(
    'transactionSmartRuleSortTypeAtom',
    'transactionCount'
  );
