import { useFinaryApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import { ApiGetProps, DividendSubtype, DividendsResponse } from '../../types';
import { createSharingLinkParams } from '../../utils';
import { useApi } from '../useApi';

export type UsePortfolioDividendsProps = {
  assetTypes?: DividendSubtype[];
} & ApiGetProps;

export const usePortfolioDividends = ({
  sharingLinkId,
  accessCode,
  skip,
  assetTypes,
}: UsePortfolioDividendsProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('with_real_estate', 'true');

  if (assetTypes?.length) {
    assetTypes.forEach((type) => {
      params.append('asset_types[]', type);
    });
  }

  return useApi<DividendsResponse>(
    API_ROUTES.portfolio.dividends.get({
      organizationId,
      membershipId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId,
    skip
  );
};
