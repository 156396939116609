import type { TransactionType } from 'core-api';
import { atomWithStorage } from 'jotai/utils';

export type CashflowTabType = TransactionType | 'available' | 'recurring';

export type CashflowHoveredCategory =
  | { categoryId: number; type: TransactionType }
  | undefined;

export type CashflowCategoryTabType =
  | 'transactions'
  | 'target'
  | 'subcategories';

export const cashflowCategoryTabPreferenceAtom =
  atomWithStorage<CashflowCategoryTabType>(
    'categoryCashflowTabPreferenceAtom',
    'transactions'
  );

export const cashflowTabPreferenceAtom = atomWithStorage<CashflowTabType>(
  'cashflowTabPreference',
  'out'
);
