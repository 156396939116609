import { atomWithStorage } from 'jotai/utils';

export const dismissedTransactionBannerAtom = atomWithStorage<
  boolean | undefined
>('dismissedTransactionBannerAtom', false);

export const yearlyBannerCloseTimestampAtom = atomWithStorage<
  string | undefined
>('yearlyBannerCloseTimestamp', undefined);

export const lifeInsuranceBannerCloseTimestampAtom = atomWithStorage<
  string | undefined
>('lifeInsuranceBannerCloseTimestamp', undefined);

export const dismissedBookBannerAtom = atomWithStorage<boolean>(
  'dismissedBookBannerAtom',
  false
);

export const subscriptionExpiredBannerCloseTimestampAtom = atomWithStorage<
  string | undefined
>('subscriptionExpiredBannerCloseTimestamp', undefined);

export const dismissedWaltioBannerAtom = atomWithStorage<boolean>(
  'dismissedWaltioBannerAtom',
  false
);

export const dismissedWaltioTopBannerAtom = atomWithStorage<boolean>(
  'dismissedWaltioTopBannerAtom',
  false
);
