import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../../config';
import { useApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { Commodity, HoldingsAccountOwnership } from '../../../types';

export type EditPreciousMetalParams = {
  buying_price: number;
  precious_metal: {
    name: string;
  };
  quantity: number;
  ownership_repartition?: HoldingsAccountOwnership[];
};

export const useEditPreciousMetal = (assetId?: string) => {
  const apiConfiguration = useApiConfig();
  const { organizationId, membershipId } = apiConfiguration;

  return useSWRMutation(
    assetId
      ? API_ROUTES.precious_metals.byId(assetId).put({
          organizationId,
          membershipId,
        })
      : null,
    async (url: string, { arg: body }: { arg: EditPreciousMetalParams }) =>
      fetchApi<Commodity>(url, {
        body,
        method: 'PUT',
        ...apiConfiguration,
      })
  );
};
