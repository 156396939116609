import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { ApiGetProps, RealEstate } from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type UseRealEstatesByIdProps = {
  realEstateId: string | number | undefined;
} & ApiGetProps;

export const useRealEstatesById = ({
  realEstateId,
  sharingLinkId,
  accessCode,
}: UseRealEstatesByIdProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  return useApi<RealEstate>(
    realEstateId
      ? API_ROUTES.real_estates.byId(realEstateId).get({
          organizationId,
          membershipId,
          params,
        })
      : null,
    undefined,
    !!sharingLinkId
  );
};
