import { Box, BoxProps } from '../Box';
import { Icon, IconName, IconProps } from '../Icon';
import { Link } from '../Link';
import { Typography } from '../Typography';

import {
  TooltipContentVariants,
  tooltipContentStyles,
} from './TooltipContent.css';

export type TooltipContentProps = TooltipContentVariants & {
  description?: React.ReactNode;
  iconLeft?: IconName;
  link?:
    | {
        href: string;
        label: React.ReactNode;
        onClick?: never;
      }
    | {
        label: React.ReactNode;
        onClick: () => void;
        href?: never;
      };
  title?: React.ReactNode;
} & Pick<BoxProps, 'style'>;

export const TooltipContent = ({
  description,
  iconLeft,
  link,
  title,
  size,
  background,
  style,
}: TooltipContentProps) => {
  const getIconSize = (): IconProps['size'] => {
    if (size === 'medium') {
      if (!title) {
        return 'medium';
      }
      return 'large';
    }
    return 'small';
  };

  const { label, ...linkProp } = link || {};

  return (
    <Box
      borderRadius="medium"
      gap="s2"
      className={tooltipContentStyles.container({ size, background })}
      style={style}
    >
      {iconLeft ? (
        <Icon
          name={iconLeft}
          size={getIconSize()}
          color={!title ? 'tertiary' : 'primary'}
        />
      ) : null}
      <Box flexDirection="column" gap="s2">
        {title ? (
          <Typography className={tooltipContentStyles.title({ size })}>
            {title}
          </Typography>
        ) : null}
        {description ? (
          <Typography className={tooltipContentStyles.description({ size })}>
            {description}
          </Typography>
        ) : null}

        {link ? (
          <Link suffixIcon="chevronRight" {...linkProp}>
            {label}
          </Link>
        ) : null}
      </Box>
    </Box>
  );
};
