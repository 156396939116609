import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../../config';
import { useApiConfig, useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  HoldingsAccountOwnership,
  Startup,
  StartupFormData,
} from '../../../types';

export type CreateStartupInvestmentsParams = {
  investments: (StartupFormData & { transaction_type: string })[];
  startup: Partial<Omit<Startup, 'id'>> &
    Pick<Startup, 'name'> & {
      id?: string | number;
    };
  ownership_repartition?: HoldingsAccountOwnership[];
};

export const useCreateStartupInvestments = () => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    API_ROUTES.startups.investments.bulk.post({ organizationId, membershipId }),
    async (
      url: string,
      { arg: body }: { arg: CreateStartupInvestmentsParams }
    ) =>
      fetchApi<Startup>(url, {
        body,
        method: 'POST',
        ...apiConfiguration,
      })
  );
};
