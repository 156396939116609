import type { CountryCode } from 'utils';

export enum QuestionnaireType {
  Onboarding = 'onboarding',
}

export type BaseQuestionnaire<T extends QuestionnaireType, A> = {
  questionnaire_attributes: A;
  questionnaire_type: T;
};

export type BaseQuestionnaireResponse<T extends QuestionnaireType, A> = {
  id: number;
  user_id: number;
} & BaseQuestionnaire<T, A>;

export type QuestionnaireOnboardingGoals =
  | 'increase_wealth'
  | 'financial_freedom'
  | 'prepare_inheritance'
  | 'save_for_large_purchase'
  | 'prepare_retirement'
  | 'evaluate_situation'
  | 'other';

export type QuestionnaireOnboardingInvestorLevel =
  | 'beginner'
  | 'intermediate'
  | 'advanced'
  | 'expert';

export type QuestionnaireOnboardingSource =
  | 'friends'
  | 'blog'
  | 'social_network'
  | 'linkedIn'
  | 'twitter'
  | 'podcast'
  | 'youtube'
  | 'search'
  | 'tiktok'
  | 'other';

export type QuestionnaireOnboardingBlocker =
  | 'not_rich_enough'
  | 'afraid_of_losing_money'
  | 'no_time_to_manage'
  | 'rely_on_banker'
  | 'spend_hours_updating_excel'
  | 'other';

export type QuestionnaireOnboardingWealthLevel =
  | 'under_50k'
  | 'from_50k_to_200k'
  | 'from_200k_to_500k'
  | 'from_500k_to_1m'
  | 'over_1m';

export type QuestionnaireOnboardingAssetTracked =
  | 'investment_account'
  | 'real_estate'
  | 'crypto'
  | 'exotic'
  | 'bank_account'
  | 'savings_account'
  | 'not_invested_yet';

export type QuestionnaireOnboardingAttributes = {
  asset_tracked: QuestionnaireOnboardingAssetTracked[];
  blocker: QuestionnaireOnboardingBlocker;
  country: CountryCode;
  goals: QuestionnaireOnboardingGoals[];
  investor_level: QuestionnaireOnboardingInvestorLevel;
  source: QuestionnaireOnboardingSource;
  wealth_level: QuestionnaireOnboardingWealthLevel;
};

export type QuestionnaireOnboarding = BaseQuestionnaire<
  QuestionnaireType.Onboarding,
  QuestionnaireOnboardingAttributes
>;

export type QuestionnaireOnboardingResponse = BaseQuestionnaireResponse<
  QuestionnaireType.Onboarding,
  QuestionnaireOnboardingAttributes
>;
