import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../../config';
import { useApiConfig, useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  CreateTransactionCategoryParam,
  TransactionParentCategory,
} from '../../../types';

export const useEditTransactionCategory = (id: number) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    API_ROUTES.transactionCategories
      .byId(id)
      .put({ organizationId, membershipId }),
    async (url: string, { arg }: { arg: CreateTransactionCategoryParam }) =>
      fetchApi<TransactionParentCategory>(url, {
        body: arg,
        method: 'PUT',
        ...apiConfiguration,
      })
  );
};
