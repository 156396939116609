export function formatNumber(
  value: number,
  locale?: string,
  options?: Intl.NumberFormatOptions
): string {
  return Intl.NumberFormat(locale, options)
    .format(value)
    .replace(/\$US$/, '$')
    .replace(/£GB$/, '£');
}

export function formatFee(value: number, locale?: string): string {
  return formatNumber(value, locale, {
    style: 'percent',
    maximumFractionDigits: 2,
  });
}

export function formatFileSize(size: number) {
  const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return (
    parseFloat((size / Math.pow(1024, i)).toFixed(2)) * 1 +
    '\u00A0' +
    ['B', 'kB', 'MB', 'GB', 'TB'][i]
  );
}

export const countDecimals = (value: number | string) => {
  // Handle number when in scientific notation
  if (value.toString().indexOf('e') !== -1) {
    const decimalPart = value.toString().split('-')[1];
    return decimalPart ? +decimalPart : 0;
  }

  if (isNaN(+value)) {
    throw new Error('countDecimals: value is NaN');
  }

  return (value.toString().split('.')[1] || '').length;
};

export const isNumberDefined = (value?: string | number | null) => {
  return (
    value !== null &&
    value !== '' &&
    value !== undefined &&
    !isNaN(Number(value))
  );
};
